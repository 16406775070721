@import "./_variables.scss";

.modal-content {
    border: 0;
}

.modal-header {
    background-color: $color-lg-pink;
    color: $color-white;
    justify-content: center;
    border-bottom: none;
}

.modal-body {
    text-align: center;
    word-wrap: break-word;
}

.modal-btn {
    display: flex;
    margin: 0 auto;
}

.space-between{
    margin: 0 15% 0 15%;
    justify-content: space-between;
}




