@import "./variables.scss";


/******************************** 
  bootstrap template media query 
*********************************/

// > 576
@mixin sm {
  @media (min-width: #{($breakpoint-sm-max)}) {
    @content;
  }
}

// > 768
@mixin md {
  @media (min-width: #{($breakpoint-md-max)}) {
    @content;
  }
}

// > 992
@mixin lg {
  @media (min-width: #{($breakpoint-lg-max)}) {
    @content;
  }
}

// > 1200
@mixin xl {
  @media (min-width: #{($breakpoint-xl-max)}) {
    @content;
  }
}


/******************************** 
          custom query 
*********************************/

// mobile (< 768px)
@mixin mobile {
  @media (max-width: #{($breakpoint-md-max - 1)}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{($breakpoint-md-max)}) {
    @content;
  }
}

// < 992
@mixin lg-mobile {
  @media (max-width: #{($breakpoint-lg-max - 1)}) {
    @content;
    display: none;
  }
}

// < 992
@mixin lg-mobile-header-user {
  @media (max-width: #{($breakpoint-lg-max - 1)}) {
    @content;
    margin-left: 0px;
    margin-top: 10px;
  }
}

// > 992
@mixin lg-desktop {
  @media (min-width: #{($breakpoint-lg-max)}) {
    @content;
    display: none !important;
  }
}

// < 992
@mixin lg-mobile-input {
  @media (max-width: #{($breakpoint-lg-max - 1)}) {
    @content;
    display: block!important;
  }
}
