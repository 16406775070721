@import "styles/styles.scss";

.header-navbar {
    display: block;
}

.navbar-light {
    background-color: #eeeded;
}

.navbar-dark {
    background-color: #000000;
}

.nav-link {
    cursor: pointer; 
}

.header-nav {
    cursor: pointer;
}

.lan-dropdown {
    @include lg-mobile();
    .lan-btn {
        &.light {
            color: #232323;
            background-color: #f9f9f9;
        }
        &.dark {
            color: #ffffff;
            background-color: #000000;
        }
        
        min-width: 112px !important;
        font-size: 1rem;
        padding: .1875rem 0.6rem;
        line-height: 1.75;
        border-radius: .15625rem;
        border-color: #c1c1c1;
        font-weight: 400;
    }

    .lan-btn:after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }

    .lan-btn:focus {
        border-color: #c1c1c1;
    }

    .lan-btn:active {
        border-color: #c1c1c1 !important;
        &.light {
            color: #f9f9f9;
            background-color: #000000;
        }
        &.dark {
            color: #232323;
            background-color: #f9f9f9;
        }
    }
    
    .lan-dropdown-menu {
        font-size: .875rem;
        padding: 0px;
        transform: translate(0px, 32px) !important;
        outline: 1px #c1c1c1 solid;
        min-width: 112px !important;
    }
    
    .lan-dropdown-item {
        line-height: 1.75;
        font-size: 1rem;
        text-align: center;
        padding: .1875rem 0rem;
        border: 1px solid #e7e7e7;
    }
}

/* HeaderTop */
.header-top-navbar {
    @include lg-mobile();
    background-color: rgba(0, 0, 0, 0.6);
    .container {
        display: inline-block !important;
        .header-top-text {
            color: #ffffff;
            cursor: pointer;
        }
    }
}

.nav-scrolled-down{
    transform:translateY(-100%); transition: all 0.3s ease-in-out;
}
.nav-scrolled-up{
    transform:translateY(0); transition: all 0.3s ease-in-out;
}

.nav-scrolled-visible {
    transform:translateY(0); transition: all 0s ease-in-out;
}

.nav-scrolled-hide {
    transform:translateY(-100%); transition: all 0s ease-in-out;
}

.avatar-circle {
    @include lg-mobile-header-user();

    width: 45px;
    height: 45px;
    margin-left: 20px;
    background-color: #8bb85d;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 24px;
    line-height: 40px;
    color: #fff;
    border: none;
}

.header-user-dropdown {
    .dropdown-menu {
        left: -32px;
        margin-top: 5px;
    }

    .header-user-dropdown-menu {
        @include lg-mobile();
    }

    .dropdown-item {
        padding: .25rem 0rem;
        border: 1px solid #e7e7e7;
        text-align: center;
        cursor: pointer;
    }
}

.headerTop-user-dropdown {
    .dropdown-menu {
        left: -15px;
        margin-top: 5px;
    }

    .dropdown-item {
        padding: .25rem 0rem;
        border: 1px solid #e7e7e7;
        text-align: center;
        cursor: pointer;
    }
}

.header-my-issues {
    @include lg-desktop();
    cursor: pointer;
    margin-left: 10px;
    line-height: 58px;
}

.sign-out-btn {
    @include lg-desktop();
}

.sign-in-btn { 
    /* Phase1 */
    display: none !important;
}
