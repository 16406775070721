/* COLOR */

//primary
$color-white: #ffffff;
$color-lg-pink: #a50034;
$color-lg-gray: #6b6b6b;

$color-gray-border: #e6e3e1;
$color-black-content-title: #212121;
$color-red: #c90000;
$color-bright-red: #ff0000;

/* MEDIA QUERY BREAKPOINT - following template default breakpoint(576, 768, 992, 1200px) */
$breakpoint-sm-max: 576px;
$breakpoint-md-max: 768px;
$breakpoint-lg-max: 992px;
$breakpoint-xl-max: 1200px;