@import "styles/styles.scss";

.footer-col {
    flex: 1!important;
}

.footer-lan-dropdown-col {
    @include lg-desktop();
}

.footer-lan-dropdown {
    padding-top: 1rem!important;
    min-width: 112px!important;

    .btn-secondary {
        color: #ffffff;
        border-color: #ffffff;
        background-color: #000000;
        font-size: 1rem;
        padding: .1875rem 0.6rem;
        line-height: 1.75;
        border-radius: .15625rem;
        border-color: #c1c1c1;
        font-weight: 400;
    }

    .btn-secondary:after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: 0;
        border-right: .3em solid transparent;
        border-bottom: .3em solid;
        border-left: .3em solid transparent;
    }

    .btn-secondary:focus {
        border-color: #c1c1c1 !important;
        background-color: #000000 !important;
    }

    .btn-secondary:active {
        color: #232323 !important;
        background-color: #f9f9f9 !important;
    }

    .btn-secondary:not(:disabled):not(.disabled):active {
        border-color: #c1c1c1 !important;
    }

    .lan-dropdown-item {
        line-height: 1.75;
        font-size: 1rem;
        text-align: center;
        padding: .25rem 0rem;
        border: 1px solid #e7e7e7;
    }
    
}
.dropdown-menu {
    min-width: 112px!important;
    font-size: .875rem;
    padding: 0rem;
    outline: 1px #c1c1c1 solid;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #232323;
    border-color: #c1c1c1;
}

.footer-policy {
    visibility: hidden;
}

.footer-text {
    cursor: pointer;
}
